import React from 'react';
import './MeshGradient.css'; // Ensure your mesh gradient styles are included here
import { FlexRow, FullTextField, LeftTextField, RightTextField} from './PhoneComponents';

const PhoneBackground = ({ shape, selectedItemIndex }) => {
   


    function CurrComponent() {
        switch (selectedItemIndex) {
            case 1:
                return "a";
            case 2:
                return "b";
            case 5:
                return "c";
            case 4:
                return "r";
            default:
                return "4";
        }
    }
    const containerStyle = {
        width: '300px',
        height: '600px', // Adjust height as needed
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        position: 'relative', // Position relative to contain absolute children
        margin: '0 auto', // Center the component horizontally
    };

    const baseStyle = {
        width: '100%',
        height: '100%',
        background: 'transparent', // Transparent background for inner content
        borderRadius: '36px', // Outer border radius to match gradient
        position: 'relative', // Relative positioning for stacking
        zIndex: '10', // Ensure inner content is above the pseudo-element
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        textAlign: 'center',
    };

    const gradientBorderStyle = {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        borderRadius: '36px', // Match the outer radius
        padding: '10px', // 16px padding to simulate the gradient border
        background: 'radial-gradient(circle, #F1ECEE,#F1ECEE, #F1ECEE)', // Mesh gradient for the border area
        zIndex: '5', // Place behind the content but above any background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box', // Include padding in the width/height calculations
    };

    const innerContentStyle = {
        width: '100%',
        height: '100%',
        background: 'white', // Transparent or semi-transparent inner content area
        borderRadius: '26px', // Inner radius to fit within the padding (36px - 16px = 20px)
        zIndex: '10', // Above the gradient border
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 30
    };

    return (
        <div style={containerStyle}>
            {/* Gradient Border */}
            <div style={gradientBorderStyle}>
                {/* Inner Transparent Content */}
                <div style={baseStyle}>
                    <div style={innerContentStyle}>
                        <LeftTextField />
                        {/* Conditionally render component corresponding to selectedItemIndex */}
                        <FullTextField label="full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhoneBackground;
