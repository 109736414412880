import React from "react";

export default function PrivacyPolicy() {
  return (
    <div
      style={{
        minWidth: "90vw",
        maxWidth: "90vw",
        color: "#001A23",
        paddingLeft: "40px",
        paddingRight: "40px !important",
        marginBottom: "20px",
        marginVertical: "40px",
        marginTop: "60px"
      }}
    >
      <div>
        <h1 style={{margin: "20px 0px 20px 0px"}}>PRIVACY POLICY-AROGRIHA</h1>
        <p>Last updated: April 03, 2023</p>
        <p>
          This privacy policy governs your use of the software application
          "Arogriha” (later referred as &nbsp;<b>“Application”</b>) for mobile
          devices that was created by Apexswarm Medsystems Private Limited.
        </p>
        <p>
          The &nbsp;<b>Application</b> is a mobile application intended to
          assist clinics,healthcare facilities and healthcare
          practitioners(later referred as "Service Partner(s)”) in delivering
          telemedicine healthcare services to individuals and patients(later
          referred as &nbsp;<b>"End-User(s)”</b>) who are constituents of the
          Union State of India and are seeking these services within the local,
          state and national jurisdictions(later referred as "Jurisdictions”) of
          the Union State of India, through this &nbsp;<b>Application</b>.
        </p>
        <h2 style={{ marginTop: "50px" }}>
          What information does the &nbsp;<b>Application</b> obtain and how is
          it used?
        </h2>
        <h3>User Provided Information</h3>
        {/*Fix This-----------------------------------------*/}
        <p>
          The &nbsp;<b>Application</b> requires the &nbsp;<b>End-Users</b> to
          provide their contact and, demographic data namely, age, gender,
          mobile number to avail telemedicine consultation services through this
          &nbsp;<b>Application</b>.
        </p>
        <p>
          The &nbsp;<b>Application</b> requires &nbsp;<b>Service Partners</b> to
          provide their contact details, demographic, professional and
          registration information in respect of their clinical practice.
        </p>
        <p>
          All prescription details are stored securely for purposes of
          compliance with prevalent laws of &nbsp;<b>Jurisdictions</b> and for
          the purpose of clinical analysis of &nbsp;<b>End-User</b>'s medical
          history in teleconsultations at any later dates. Prescriptions and
          medical history records of an &nbsp;<b>End-User</b> are retrievable by
          &nbsp;<b>Service Partner</b> only when the &nbsp;<b>End-User</b>{" "}
          avails a teleconsultation service under it, through this &nbsp;
          <b>Application</b>. Any use of prescription details and medical
          history of an &nbsp;<b>End-User</b> for research and development of
          the &nbsp;<b>Application</b> is initiated only after explicit approval
          of the &nbsp;<b>End-User</b> within the &nbsp;<b>Application</b> on
          &nbsp;<b>prescription-by-prescription</b>
          basis for prescriptions and medical records generated through this
          &nbsp;<b>&nbsp;Application</b> for the &nbsp;<b>End-User</b>.
        </p>
        <p>
          All live video and audio conversations through the &nbsp;
          <b>&nbsp;Application</b> are transmitted through secure protocols, and
          conversations are not stored after any session in any form whatsoever.
        </p>
        <h3>Automatically Collected Information</h3>
        <p>
          The &nbsp;<b>&nbsp;Application</b> does not collect any information
          automatically.
        </p>
        <p>
          The &nbsp;<b>Application</b> does not share any information
          automatically with third parties.
        </p>
        <ul>
          <li>
            Does the &nbsp;<b>Application</b> collect precise real time location
            information of the device?
          </li>
        </ul>
        <p>
          The &nbsp;<b>Application</b> does not collect precise information
          about the location of your mobile device.
        </p>
        <ul>
          <li>
            Do &nbsp;<b>Third parties</b> see and/or have access to information
            obtained by the &nbsp;<b>Application</b>?
          </li>
        </ul>
        <p>
          A &nbsp;<b>Service Partner</b> can access an &nbsp;<b>End-user</b>'s
          medical history only if the &nbsp;<b>End-User</b> avails a
          teleconsultation service under it, through this &nbsp;
          <b>Application</b>. The User Provided Information cannot be accessed
          by any other external entities with the exception of law enforcement
          agencies under the purview of these &nbsp;<b>Jurisdictions</b> in
          matters of legal compliance.
        </p>
        <ul>
          <li>What are my opt-out rights?</li>
        </ul>
        <p>
          Request by &nbsp;<b>End-Users</b> to delete their personal and medical
          records shall be accounted for and addressed in accordance with
          prevalant laws of concerned &nbsp;<b>Jurisdictions</b> at the time of
          the seeking of the said request.
        </p>
        <p>
          You can easily discontinue accessing &nbsp;<b>Application</b> and its
          services by uninstalling the Application from your mobile device. You
          may use the standard uninstall processes as may be available as part
          of your mobile device or via the mobile application marketplace or
          network.
        </p>
        <h2 style={{ marginTop: "50px" }}>
          Data Retention Policy, Managing Your Information
          {/*Fix This-----------------------------------------*/}
        </h2>
        <p>
          This &nbsp;<b>Application</b> does not automatically collect, share
          nor retain any information.
        </p>
        <p>
          All prescription details are stored securely for purposes of
          compliance with prevalent laws of concerned &nbsp;<b>Jurisdictions</b>{" "}
          and for the purpose of clinical analysis of &nbsp;<b>End-User</b>'s
          medical history in teleconsultations at any later dates. Prescriptions
          and medical history records of an &nbsp;<b>End-User</b> are
          retrievable by &nbsp;<b>Service Partner</b> only when the &nbsp;
          <b>End-User</b> avails a teleconsultation service under it,through
          this &nbsp;<b>Application</b>. Any use of prescription details and
          medical history of an &nbsp;<b>End-User</b> for research and
          development of the &nbsp;<b>Application</b> is initiated only after
          explicit approval of the &nbsp;<b>End-User</b> within the &nbsp;
          <b>Application</b> on &nbsp;<b>prescription-by-prescription</b>
          basis for prescriptions and medical records generated through this
          &nbsp;<b>Application</b> for the &nbsp;<b>End-User</b>.
        </p>
        <p>
          Request by &nbsp;<b>End-Users</b> to delete their personal and medical
          records shall be accounted for and addressed in accordance with
          prevalant laws of concerned &nbsp;<b>Jurisdictions</b> at the time of
          the seeking of the said request.
        </p>
        <h2 style={{ marginTop: "50px" }}>Children</h2>
        <p>
          Our &nbsp;<b>Application</b> does not address anyone under the age of
          18. We do not knowingly collect personally identifiable information
          from anyone under the age of 18. We do not use the &nbsp;
          <b>Application</b> to knowingly solicit data from or market to
          children under the age of 18. &nbsp;<b>End-Users</b>
          seeking teleconsultation services as Guardians on behalf of their
          children under the age of 18 are required to account for and address
          the safety and privacy concerns of their children at their own
          descretion.
        </p>
        <h2 style={{ marginTop: "50px" }}>Security</h2>
        <p>
          We are concerned about safeguarding the confidentiality of your
          information.
        </p>
        <p>
          Please be aware that, although we endeavor provide reasonable security
          for information we process and maintain, no security system can
          prevent all potential security breaches (e.g., a zero-day attack
          targeting mobile Operating System – OS).
        </p>
        <p>
          In order to ensure an optimal security, you shall regularly install
          any Application’s update available from the mobile &nbsp;
          <b>Application</b> marketplace.
        </p>
        <p>
          We also advise you to regularly check for any Android OS update(s)
          that could be available and to enable automatic updates.
        </p>
        <h2 style={{ marginTop: "50px" }}>Changes</h2>
        <p>
          This Privacy Policy may be updated from time to time for any reason.
        </p>
        <p>
          Please be aware that, although we endeavor provide reasonable security
          for information we process and maintain, no security system can
          prevent all potential security breaches (e.g., a zero-day attack
          targeting mobile Operating System – OS).
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes, as continued use is deemed approval of all changes. Changes
          to this Privacy Policy are effective when they are posted on this
          page.
        </p>
        <h2 style={{ marginTop: "50px" }}>Your Consent</h2>
        <p>
          You acknowledge that this Privacy Policy is a part of the usage of the
          Platform, and You unconditionally agree that becoming a &nbsp;
          <b>End-User</b> or a &nbsp;<b>Service Partner</b> of the Platform,
          signifies Your
        </p>
        <ul>
          <li>confirmation that you are more than 18 years of age;</li>
          <li>assent to this Privacy Policy, and</li>
          <li>
            consent to the &nbsp;<b>Application</b> using, collecting,
            processing and/or disclosing Your Personal Information in the manner
            and for the purposes set out in this Privacy Policy.
          </li>
          <li>
            Your use of the &nbsp;<b>Application</b> and its services is subject
            to this Privacy Policy.
          </li>
        </ul>
        <h2 style={{ marginTop: "50px" }}>Contact Us</h2>
        <p>
          If you have any questions regarding privacy while using the &nbsp;
          <b>Application</b>, or have questions about our practices, please
          contact us via email at:
          <br />
          contact [at] arogriha [dot] com
        </p>
      </div>
    </div>
  );
}