import React, { useState, useEffect } from "react";
import "./MeshGradient.css";

const gradientLayerClasses = {
  1: ["gradient-layer-1", "gradient-layer-2", "gradient-layer-3", "gradient-layer-4"],
  2: ["gradient-layer-5", "gradient-layer-6", "gradient-layer-7", "gradient-layer-8"],
  3: ["gradient-layer-9", "gradient-layer-10", "gradient-layer-11", "gradient-layer-12"],
  4: ["gradient-layer-13", "gradient-layer-14", "gradient-layer-15", "gradient-layer-16"],
};

const MeshGradient = ({ containerIdentifier }) => {
  const layersToShow = gradientLayerClasses[containerIdentifier] || [];

  return (
    <div className="gradient-container">
      {layersToShow.map((layerClass, index) => (
        <div
          key={index}
          className={`gradient-layer ${layerClass}`}
        />
      ))}
    </div>
  );
};

export default MeshGradient;
