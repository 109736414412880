import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MeshGradient from "./MeshGradient"; // Import the MeshGradient component

export default function RequestDemo() {
  return (
    <Box
      id="hero"
      sx={{
        position: "relative", // Ensure proper layering
        width: "100%",
        // minHeight: { xs: '100vh', lg: "auto" }, // Set to full viewport height
        overflow: "hidden", // Hide overflow to keep it neat
      }}
    >
      {/* MeshGradient as background */}
      {/* <MeshGradient /> */}

      {/* Hero content */}
      <Container
        sx={{
          position: "relative", // Ensures content is above the background
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 1, // Ensure content is above the background
          pt: { xs: 14, sm: 16 },
          pb: { xs: 8, sm: 14 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
              fontSize: "clamp(3.5rem, 10vw, 4rem)",
            }}
          >
            Ask a&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "clamp(3rem, 10vw, 4rem)",
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              Question
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
          >
            Have a question or need more information? Elevate your experience
            with our premium features and services—just reach out, and we'll be
            happy to assist you.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              inputProps={{
                autoComplete: "off",
                "aria-label": "Enter your email address",
              }}
            />
            <Button variant="contained" color="primary">
              Send Contact
            </Button>
          </Stack>
          {/* <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
      </Container>
    </Box>
  );
}
