import React from "react";
import "./FeatureGradient.css";

const FeatureGradient = () => {
  return (
    <div className="gradient-container-ft">
      {/* Gradient Layers */}
      <div className="gradient-layer-ft gradient-layer-ft-1"></div>
      <div className="gradient-layer-ft gradient-layer-ft-2"></div>
      <div className="gradient-layer-ft gradient-layer-ft-3"></div>
      <div className="gradient-layer-ft gradient-layer-ft-4"></div>
    </div>
  );
};

export default FeatureGradient;