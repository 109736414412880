import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";
import PhoneBackground from "./PhoneBackground";
import FeatureGradient from "./FeatureGradient";
import MobileScreen from "./MobileScreen";
import * as animationData from "../assets/lottie2.json";
import Lottie from "react-lottie";

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: "Streamline Appointments",
    description:
      "Receive and manage appointments efficiently while keeping patients informed with instant notifications on appointment confirmations.",
    imageLight: "",
    imageDark: "",
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: "Multi-Platform Support",
    description:
      "Optimized performance across web, mobile, and desktop platforms for a consistent and reliable user experience.",
    imageLight: "",
    imageDark: "",
  },
  {
    icon: <DevicesRoundedIcon />,
    title: "Instant Prescriptions",
    description:
      "Quickly generate and send prescriptions to patients directly, enhancing efficiency in telemedicine services.",
    imageLight: "",
    imageDark: "",
  },
  {
    icon: <DevicesRoundedIcon />,
    title: "Flexible Scheduling",
    description:
      "Easily edit and update doctor schedules, manage active shifts, and customize consultation lengths to suit needs.",
    imageLight: "",
    imageDark: "",
  },
  {
    icon: <ViewQuiltRoundedIcon />,
    title: "Dynamic Clinical Interface",
    description:
      "Utilize speciality-sensitive tools to automate and customize prescriptions, ensuring tailored healthcare delivery.",
    imageLight: "",
    imageDark: "",
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [visibleStartIndex, setVisibleStartIndex] = React.useState(0);
  const visibleCount = 3; // Number of chips/cards to display at once
  const selectedFeature = items[selectedItemIndex];
  const touchStartXRef = React.useRef(0);
  const touchEndXRef = React.useRef(0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Helper function to get visible items
  const getVisibleItems = () => {
    return items
      .slice(visibleStartIndex, visibleStartIndex + visibleCount)
      .concat(
        items.slice(
          0,
          Math.max(0, visibleStartIndex + visibleCount - items.length)
        )
      );
  };

  const handleItemClick = (index) => {
    const currentIndex = (visibleStartIndex + index) % items.length;
    setSelectedItemIndex(currentIndex);

    // If clicked item is not the middle item (i.e., index 1), shift the visible items
    if (index !== 1) {
      const newVisibleStartIndex =
        (visibleStartIndex + index - 1 + items.length) % items.length;
      setVisibleStartIndex(newVisibleStartIndex);
    }
  };

  const handleDotClick = (index) => {
    setSelectedItemIndex(index);
    setVisibleStartIndex(index);
  };
  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const deltaX = touchEndXRef.current - touchStartXRef.current;

    if (deltaX > 50) {
      // Swipe right (show previous item)
      const newVisibleStartIndex =
        (visibleStartIndex - 1 + items.length) % items.length;
      setVisibleStartIndex(newVisibleStartIndex);
      setSelectedItemIndex(newVisibleStartIndex);
    } else if (deltaX < -50) {
      // Swipe left (show next item)
      const newVisibleStartIndex = (visibleStartIndex + 1) % items.length;
      setVisibleStartIndex(newVisibleStartIndex);
      setSelectedItemIndex(newVisibleStartIndex);
    }

    // Reset touch positions
    touchStartXRef.current = 0;
    touchEndXRef.current = 0;
  };

  return (
    <Container
      id="features"
      sx={{ py: { xs: 8, sm: 16 }, position: "relative" }}
    >
      <Grid container spacing={10}>
        {/* Feature description for mobile and desktop */}
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              Product features
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Digitize your healthcare practice with cross-platform apps.
            </Typography>
          </div>

          {/* Mobile view: chips and carousel dots */}
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "flex", sm: "none" } }}
          >
            {getVisibleItems().map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex ===
                        (visibleStartIndex + index) % items.length
                        ? "#7CACAC"
                        : "";
                    }
                    return selectedItemIndex ===
                      (visibleStartIndex + index) % items.length
                      ? "primary.light"
                      : "";
                  },
                  backgroundColor:
                    selectedItemIndex ===
                    (visibleStartIndex + index) % items.length
                      ? "primary.main"
                      : "",
                  "& .MuiChip-label": {
                    color:
                      selectedItemIndex ===
                      (visibleStartIndex + index) % items.length
                        ? "#7CACAC"
                        : "",
                  },
                }}
              />
            ))}
          </Grid>

          {/* Mobile view: card content */}
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: "block", sm: "none" },
              mt: 4,
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div style={{marginTop: "10px"}} />
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
              // isStopped={isStopped}
              // isPaused={isPaused}
            />
               <div style={{marginTop: "10px"}} />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography
                color="text.primary"
                variant="body2"
                fontWeight="bold"
              >
                {selectedFeature.title}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ my: 0.5 }}
              >
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  "& > svg": { transition: "0.2s" },
                  "&:hover > svg": { transform: "translateX(2px)" },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: "1px", ml: "2px" }}
                />
              </Link>
            </Box>
          </Box>

          {/* Mobile view: carousel dots */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mt: 2, display: { xs: "flex", sm: "none" } }}
          >
            {items.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor:
                    selectedItemIndex === index ? "primary.main" : "#b9d5d0",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </Stack>

          {/* Desktop view: cards and dots */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            {/* Stack of cards */}
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              useFlexGap
              sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
            >
              {getVisibleItems().map(({ icon, title, description }, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    p: 3,
                    // height: "fit-content",
                    minHeight: "140px",
                    width: "100%",
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor:
                      selectedItemIndex ===
                      (visibleStartIndex + index) % items.length
                        ? "action.selected"
                        : undefined,
                    borderColor: (theme) => "none",
                  }}
                >
                  {selectedItemIndex ===
                    (visibleStartIndex + index) % items.length && (
                    <FeatureGradient />
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      textAlign: "left",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: { md: "center" },
                      gap: 2.5,
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        color: (theme) => {
                          if (theme.palette.mode === "light") {
                            return selectedItemIndex ===
                              (visibleStartIndex + index) % items.length
                              ? "#ce8075"
                              : "grey.300";
                          }
                          return selectedItemIndex ===
                            (visibleStartIndex + index) % items.length
                            ? "primary.main"
                            : "grey.700";
                        },
                      }}
                    >
                      {icon}
                    </Box>
                    <Box sx={{ textTransform: "none" }}>
                      <Typography
                        color="text.primary"
                        variant="body2"
                        fontWeight="bold"
                      >
                        {title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{ my: 0.5 }}
                      >
                        {description}
                      </Typography>
                      <Link
                        color="#ce8075"
                        variant="body2"
                        fontWeight="bold"
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          "& > svg": { transition: "0.2s" },
                          "&:hover > svg": { transform: "translateX(2px)" },
                        }}
                        onClick={(event) => {
                          // event.stopPropagation();
                        }}
                      >
                        <span>Learn more</span>
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ mt: "1px", ml: "2px" }}
                        />
                      </Link>
                    </Box>
                  </Box>
                </Card>
              ))}
            </Stack>

            {/* Carousel Dots to the Right of the Stack for Desktop View */}
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{ ml: 2, display: { xs: "none", sm: "flex" } }}
            >
              {items.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: selectedItemIndex === index ? 12 : 12,
                    height: selectedItemIndex === index ? 12 : 12,
                    borderRadius: "50%",
                    backgroundImage:
                      selectedItemIndex === index
                        ? "linear-gradient(135deg, #F9C1BB, #fee9e7)" // Diagonal angular gradient when active
                        : "linear-gradient(135deg, #eff5f4, #b9d5d0)", // No gradient when inactive
                    backgroundColor: "transparent",
                    borderColor:
                      selectedItemIndex === index ? "none" : "primary.main",
                    borderWidth: selectedItemIndex === index ? 0 : 1,
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                  onClick={() =>
                    handleItemClick(
                      (index - visibleStartIndex + items.length) % items.length
                    )
                  }
                />
              ))}
            </Stack>
          </Stack>
        </Grid>

        {/* Display phone background in desktop view */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", sm: "flex" },
            minWidth: "400px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <PhoneBackground selectedItemIndex={selectedItemIndex} /> */}
          <MobileScreen />
          {/* <GlassmorphismCard /> */}
        </Grid>
      </Grid>
    </Container>
  );
}
