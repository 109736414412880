import { default as icon3 } from '../assets/icons/icon3.png';
import { default as icon4 } from '../assets/icons/icon4.png';
import { default as icon5 } from '../assets/icons/icon5.png';
import { default as icon6 } from '../assets/icons/icon6.png';
import { default as icon10 } from '../assets/icons/icon10.png';
import { default as icon11 } from '../assets/icons/icon11.png';
import { default as icon12 } from '../assets/icons/icon12.png';
import { default as icon13 } from '../assets/icons/icon13.png';
import { default as icon14 } from '../assets/icons/icon14.png';
import { default as icon15 } from '../assets/icons/icon15.png';
import { default as icon16 } from '../assets/icons/icon16.png';
import { default as icon17 } from '../assets/icons/icon17.png';
import { default as icon18 } from '../assets/icons/icon18.png';
import { default as icon20 } from '../assets/icons/icon20.png';
import { default as icon25 } from '../assets/icons/icon25.png';
import { default as icon30 } from '../assets/icons/icon30.png';
import { default as icon36 } from '../assets/icons/icon36.png';
import { default as icon37 } from '../assets/icons/icon37.png';
import { default as icon38 } from '../assets/icons/icon38.png';
import { default as icon39 } from '../assets/icons/icon39.png';

export const icons = {
    icon3,
    icon4,
    icon5,
    icon6,
    icon10,
    icon11,
    icon12,
    icon13,
    icon14,
    icon15,
    icon16,
    icon17,
    icon18,
    icon20,
    icon25,
    icon30,
    icon36,
    icon37,
    icon38,
    icon39
};
