import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ContactInfo = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        padding: 3,
      }}
    >
      <Box
        // elevation={3}
        sx={{
          padding: 4,
          maxWidth: 400,
          // borderRadius: 2,
          backgroundColor: "transparent",
        }}
      >
        <Typography variant="h4" color="primary.main" gutterBottom>
          Contact details for support and assistance.
        </Typography>
        <Typography variant="body1" color="text.primary">
          <strong>Email:</strong> contact@arogriha.com
        </Typography>
        <Typography variant="body1" color="text.primary">
          <strong>Phone:</strong> +917993529100
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactInfo;
