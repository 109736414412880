// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import Support from './SupportPage';
import Privacy from './PrivacyPage';
import MeshGradient from './components/MeshGradient';
// import ShadowGradientBox from './components/ShadowGradientBox';
// import Glassmorphism from "./components/GlassMorphism";
// import GlassmorphismCard from "./components/GlassmorphismCard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/support" element={<Support />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;
