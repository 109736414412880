import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export const LeftTextField = ({ label }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      style={{ width: "40px", borderColor: "red" }}
      label={label}
    />
  );
};

export const RightTextField = ({ label }) => {
  return (
    <TextField
      variant="outlined"
      style={{ width: "40px", marginLeft: "5%" }}
      label={label}
    />
  );
};

export const FullTextField = ({ label }) => {
  return <TextField label={label} style={{ width: "80px"  }} />;
};

export const FlexRow = ({ children, justify, align }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: justify ? justify : "space-evenly",
        alignItems: align ? align : "center",
      }}
    >
      {children}
    </Box>
  );
};

export default function Feature1() {
  return (
    <div>
      <FlexRow>
        <LeftTextField label="Test Label 1" />
        <RightTextField label="Test 2" />
      </FlexRow>
      <FullTextField label="Test 3" />
    </div>
  );
}
// export const Feature2 = () => {
//   <div>
//     <FlexRow>
//       <LeftTextField label="Test Label 1" />
//       <RightTextField label="Test 2" />
//     </FlexRow>
//     <FullTextField label="Test 3" />
//   </div>;
// };

// export const Feature3 = () => {
//   <div>
//     <FlexRow>
//       <LeftTextField label="Test Label 1" />
//       <RightTextField label="Test 2" />
//     </FlexRow>
//     <FullTextField label="Test 3" />
//   </div>;
// };

// export const Feature4 = () => {
//   <div>
//     <FlexRow>
//       <LeftTextField label="Test Label 1" />
//       <RightTextField label="Test 2" />
//     </FlexRow>
//     <FullTextField label="Test 3" />
//   </div>;
// };

// export const Feature5 = () => {
//   <div>
//     <FlexRow>
//       <LeftTextField label="Test Label 1" />
//       <RightTextField label="Test 2" />
//     </FlexRow>
//     <FullTextField label="Test 3" />
//   </div>;
// };

// export const Feature6 = () => {
//   <div>
//     <FlexRow>
//       <LeftTextField label="Test Label 1" />
//       <RightTextField label="Test 2" />
//     </FlexRow>
//     <FullTextField label="Test 3" />
//   </div>;
// };
