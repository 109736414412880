import React, { useState } from "react";
import { alpha, Avatar, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Divider, styled } from "@mui/material";
import MeshGradient from "./MeshGradient"; // Import the MeshGradient component
import Lottie from "react-lottie";
import * as animationData from "../assets/lottie3.json";
import { icons } from "./icons";
import {
  Person as PersonIcon,
  Add as AddIcon,
  FolderSpecial as CollectionsBookmarkIcon,
  SearchRounded as SearchRoundedIcon,
  NavigateBeforeOutlined as ArrowBackIosNewOutlined,
  NavigateNextOutlined as ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { jsonIcons } from "./images";
import {
  AndroidIcon,
  AppStoreIcon,
  FirefoxIcon,
  CloudIcon,
} from "../assets/svgs";
// import AddIcon from '@mui/icons-material/Add';
export default function Hero() {
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const linGradients = [
    "linear-gradient(135deg, #7e1716, #c32422)",
    "linear-gradient(135deg, #4c1501, #BB3402)",
    "linear-gradient(135deg, #176b15, #0e400d)",
  ];

  const HeroFeatures = [
    {
      image: icons.icon10,
      title: "Medical History",
    },
    {
      image: icons.icon3,
      title: "Virtual Consultations",
    },
    {
      image: icons.icon38,
      title: "Digital Prescriptions",
    },
    {
      image: icons.icon11,
      title: "Medication Schedules",
    },
  ];
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const CustomBox = ({
    width,
    height,
    mh,
    children,
    flex,
    justify,
    padding,
    opacity,
    align,
  }) => {
    console.log("mh: ", mh);

    return (
      <Box
        sx={(theme) => ({
          width: width ? width : "100%", // Default width if not provided
          height: height ? height : "100%", // Optional height
          maxHeight: mh ? mh : "none",
          display: "flex",
          flexDirection: flex,
          alignItems: align ? align : "center",
          justifyContent: justify,
          gap: 2,
          backgroundColor: alpha("#FFFFFF", opacity ? opacity : 0.1), // Glassmorphism-like background
          borderRadius: "10px",
          padding: padding ? padding : 2,
        })}
      >
        {children}
      </Box>
    );
  };

  const CustomCard = ({ image, title }) => {
    return (
      <>
        <Box
          sx={(theme) => ({
            width: "60%", // Default width if not provided
            height: "90px", // Optional height
            display: { xs: "none", sm: "flex" },
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 2,
            backgroundColor: alpha("#FFFFFF", 0.1), // Glassmorphism-like background
            borderRadius: "10px",
            padding: 1,
          })}
        >
          <div style={{ minWidth: "80px" }}>
            <Box
              component={"img"}
              src={image}
              sx={{ maxHeight: "60px", minWidth: "auto" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              minWidth: "100%",
            }}
          >
            <p>{title}</p>
            <CustomBox
              width="100%"
              height={"25px"}
              flex="column"
              opacity={0.4}
            />
          </div>
        </Box>
        {/* Mobile */}
        <Box
          sx={(theme) => ({
            width: "60%", // Default width if not provided
            height: "30px", // Optional height
            display: { xs: "flex", sm: "none" },
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 2,
            backgroundColor: alpha("#FFFFFF", 0.1), // Glassmorphism-like background
            borderRadius: "10px",
            padding: 0,
          })}
        >
          <div style={{ minWidth: "30px" }}>
            <Box
              component={"img"}
              src={image}
              sx={{ maxHeight: "23px", minWidth: "auto" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              minWidth: "100%",
            }}
          >
            <p style={{ fontSize: "8px" }}>{title}</p>
            <Box
              sx={(theme) => ({
                width: "100%", // Default width if not provided
                height: "100%", // Optional height
                maxHeight: "none",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 0,
                backgroundColor: alpha("#FFFFFF", 0.4), // Glassmorphism-like background
                borderRadius: "10px",
                padding: 1,
              })}
            />
          </div>
        </Box>
      </>
    );
  };

  return (
    <Box
      id="hero"
      sx={{
        position: "relative", // Ensure proper layering
        width: "100%",
        // height: '90vh', // Set to full viewport height
        overflow: "hidden", // Hide overflow to keep it neat
      }}
    >
      {/* MeshGradient as background */}
      <MeshGradient containerIdentifier={1} />

      {/* Hero content */}
      <Container
        sx={{
          position: "relative", // Ensures content is above the background
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 1, // Ensure content is above the background
          mt: { sm: 5 },
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
              fontSize: "clamp(3.5rem, 10vw, 4rem)",
            }}
          >
            Digitized&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "clamp(3rem, 10vw, 4rem)",
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              healthcare
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
          >
            Integrate your healthcare practice with diverse systems and expand
            your patient base to wider horizons.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{
              pt: 2,
              width: { xs: "100%", sm: "auto" },
              mt: { sm: "20px" },
            }}
          >
            {/* <GlassHero /> */}
            {/* <GlassHero /> */}
          </Stack>
        </Stack>
        {/*--------------- Image Asset Main--------------------- */}
        <Box
          // id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            height: { xs: 300, sm: 600 },
            width: "100%",
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
            display: "flex",
            flexDirection: "column",
          })}
        >
          {/* Test icons */}
          {/* <Box
            sx={{
              // width: "100%",
              // height: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: alpha("#FFFFFF", 0.1),
              borderRadius: "10px",
              padding: 2,
              gap: 2,
            }}
          >
            {jsonIcons.map((el, index) => {
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    component={"img"}
                    src={el.src}
                    sx={{ maxHeight: el?.type, width: "auto" }}
                  />
                  <p>{el?.name}</p>
                </div>
              );
            })}
          </Box> */}
          <CustomBox
            flex={"row"}
            justify={"space-evenly"}
            width="100%"
            height="50px"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{ ml: 2, display: { xs: "none", sm: "flex" } }}
            >
              {[1, 2, 3].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundImage: linGradients[index],
                    backgroundColor: "transparent",
                    // borderColor:
                    //   selectedItemIndex === index ? "none" : "primary.main",
                    // borderWidth: selectedItemIndex === index ? 0 : 1,
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                  onClick={() => {}}
                />
              ))}
              <ArrowBackIosNewOutlined
                style={{ marginLeft: "20px", color: "#202022" }}
              />
              <ArrowForwardIosOutlined
                style={{ marginLeft: "10px", color: "#202022" }}
              />
            </Stack>
            <CustomBox
              flex={"row"}
              justify={"center"}
              color={""}
              width={"100%"}
            >
              <SearchRoundedIcon
                style={{ marginLeft: "10px", color: "#202022" }}
              />
              <Typography
                component="span"
                variant="p"
                sx={{
                  fontSize: "10px",
                  color: "#202022",
                }}
              >
                Search or enter website name
              </Typography>
            </CustomBox>
            <CustomBox flex={"row"} justify={"flex-start"} width={"90px"}>
              <AddIcon style={{ color: "#202022" }} />
              <CollectionsBookmarkIcon style={{ color: "#202022" }} />
            </CustomBox>
          </CustomBox>
          <Divider />

          <CustomBox flex={"row"} justify={"space-evenly"} width="100%">
            <CustomBox
              flex={"column"}
              justify={"flex-start"}
              width="100%"
              align={"flex-start"}
            >
              {HeroFeatures.map((it, ind) => {
                return <CustomCard image={it.image} title={it.title} />;
              })}
              {/* <div style={{marginTop: "10px"}} /> */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minWidth: "70%",
                }}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    minWidth: "130px",
                    padding: 1,
                    border: "1px solid #3CD8D1", // Added border property
                    borderRadius: 3,
                    backgroundColor: "#3CD8D1",
                  }}
                >
                  <Typography>Android</Typography>
                  <AndroidIcon />
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    minWidth: "130px",
                    padding: 1,
                    border: "1px solid #F86E5E", // Added border property
                    borderRadius: 3,
                    backgroundColor: "#F86E5E",
                    marginLeft: 5,
                  }}
                >
                  <Typography>iOS</Typography>
                  <AppStoreIcon />
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    minWidth: "130px",
                    padding: 1,
                    border: "1px solid #3CD8D1", // Added border property
                    borderRadius: 3,
                    backgroundColor: "#3CD8D1",
                    marginLeft: 5,
                  }}
                >
                  <Typography>Web</Typography>
                  <CloudIcon />
                  
                </Box>
              </div> */}
            </CustomBox>
            {/* Right Side - Avatar and Lottie */}
            <CustomBox flex={"column"} justify={"flex-start"} width="20%">
              <Box style={{ marginTop: { sx: 0, sm: 30 } }} />
              <Avatar>
                <PersonIcon />
              </Avatar>
              <CustomBox
                width="100%"
                height={"25px"}
                flex="column"
                opacity={0.4}
              />
              <CustomBox
                width="100%"
                height={"25px"}
                flex="column"
                opacity={0.4}
              />
              <Box sx={{ marginTop: { xs: 0, sm: 60 } }} />
              {/* <Lottie
                options={defaultOptions}
                height={200}
                width={200}
                isStopped={isStopped}
                isPaused={isPaused}
              /> */}
            </CustomBox>
          </CustomBox>
        </Box>
      </Container>
    </Box>
  );
}
