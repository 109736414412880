import React, { useState } from 'react';
import './HighlightGradient.css';

const HighlightGradient = () => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`gradient-container-ht`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* Gradient Layers */}
      <div className={`gradient-layer-ht ${hover ? 'gradient-layer-ht-1-inv' : 'gradient-layer-ht-1'}`}></div>
      <div className={`gradient-layer-ht ${hover ? 'gradient-layer-ht-2-inv' : ' gradient-layer-ht-2'}`}></div>
      <div className={`gradient-layer-ht ${hover ? 'gradient-layer-ht-3-inv' : ' gradient-layer-ht-3'}`}></div>
      <div className={`gradient-layer-ht ${hover ? 'gradient-layer-ht-4-inv' : ' gradient-layer-ht-4'}`}></div>
    </div>
  );
};

export default HighlightGradient;
