import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  FlexRow,
  LeftTextField,
  FullTextField,
  RightTextField,
} from "./PhoneComponents";
import { alpha } from "@mui/material";

import icon36 from "../assets/icons/icon36.png";
import icon17 from "../assets/icons/icon36.png";
function MobileScreen() {
  const CustomBox = ({
    width,
    height,
    children,
    flex,
    justify,
    padding,
    opacity,
    align,
  }) => {
    return (
      <Box
        sx={(theme) => ({
          width: width ? width : "100%", // Default width if not provided
          height: "29px", // Optional height
          display: "flex",
          flexDirection: flex,
          alignItems: align ? align : "center",
          justifyContent: "center",
          gap: 2,
          //   backgroundColor: alpha("#FFFFFF", opacity ? opacity : 0.7), // Glassmorphism-like background
          backgroundColor: "#3CD8D1",
          borderRadius: "10px",
          padding: padding ? padding : 2.5,
        })}
      >
        {/* {children} */}
        <p style={{color: "#F1ECEE", fontSize: "16px", opacity: 0.9}}>Book Appointment</p>
      </Box>
    );
  };

  const NonInteractiveInput = ({
    value,
    width,
    placeholder = "Enter text...",
    ml,
  }) => {
    return (
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        readOnly
        onFocus={(e) => e.target.blur()} // Prevent focus by immediately blurring
        style={{
          pointerEvents: "none", // Prevent any interaction
          backgroundColor: "#F1ECEE", // Background color to mimic a regular input
          color: "#000", // Text color
          border: "1px solid #F1ECEE", // Border to mimic a regular input
          padding: "8px", // Padding to mimic a regular input
          borderRadius: "8px", // Rounded corners
          width: width, // Set a width for the input
          marginLeft: ml ? ml : 0,
        }}
      />
    );
  };

  const NonInteractiveButton = ({ text, width, ml, align, invert }) => {
    return (
      <button
        type="button"
        style={{
          pointerEvents: "none", // Prevent any interaction
          background: `radial-gradient(circle at top left, ${
            invert ? "#CAAAA2" : `#F1EAEC`
          }, ${invert ? "#F1EAEC" : "#CAAAA2"})`, // Radial gradient background
          color: "#002736", // Text color
          border: "none", // No border
          padding: "10px 20px", // Padding
          borderRadius: "8px", // Rounded corners
          cursor: "default", // Default cursor to indicate no action
          width: width, // Set a width for the button
          marginTop: "20px", // Margin to separate from other elements
          marginLeft: ml ? ml : 0,
          display: "flex",
          flexDirection: "column",
          alignItems: align ? align : "center",
          opacity: 0.4,
          minHeight: "28px"
        }}
      >
        {/* {text} */}
      </button>
    );
  };

  return (
    <Paper
      elevation={3} // Optional, adds some shadow
      sx={{
        width: 300, // Set width to resemble a mobile screen size
        height: 560, // Set height to resemble a mobile screen size
        border: "18px solid #F1ECEE",
        borderRadius: "16px", // Optional, for rounded corners
        overflow: "hidden", // Hide content that overflows the component
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff", // Optional, white background inside the screen
      }}
    >
      {/* Content inside the mobile screen */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: 2,
        }}
      >
        {/* <Box
          component="img"
          src={icon36}
          sx={{ maxWidth: "30px", minWidth: "30px", opacity: 0.4 }}
        /> */}
        <div style={{ marginTop: "20px" }} />
        <Box
          component="img"
          src={icon17}
          sx={{
            maxWidth: "80px",
            minWidth: "80px",
            opacity: 0.2,
            alignSelf: "center",
          }}
        />
        <div style={{ marginTop: "35px" }} />

        {/* Name and Age Inputs */}
        <FlexRow>
          <NonInteractiveButton width="105px" text="Name" align="flex-start" />
          <NonInteractiveButton
            width="105px"
            text="Age"
            align="flex-start"
            ml="20px"
          />
        </FlexRow>
        <div style={{ marginTop: "5px" }} />
        {/* Date and Time Inputs */}

        <FlexRow>
          <NonInteractiveButton
            width="105px"
            text="Gender"
            align="flex-start"
          />
          <NonInteractiveButton
            width="105px"
            text="Contact"
            align="flex-start"
            ml="20px"
          />
        </FlexRow>
        <div style={{ marginTop: "5px" }} />
        {/* Contact Details Input */}
        <NonInteractiveButton
          width="100%"
          text="Reason for appointment"
          align="flex-start"
        />
        <div style={{ marginTop: "5px" }} />
        {/* Reason for Appointment Input */}
        {/* <NonInteractiveInput
          width="100%"
          placeholder="Reason for Appointment"
          style={{ marginTop: "10px" }}
        /> */}

        {/* Non-interactive Book Appointment Button */}
        <NonInteractiveButton
          text="Book Appointment"
          width="100%"
          invert={true}
        />        <div style={{ marginTop: "35px" }} />
                <CustomBox width="100%" height="25px" />
      </Box>
    </Paper>
  );
}

export default MobileScreen;
